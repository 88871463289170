<template>
  <div class="activities">
    <ul>
      <activity
        v-for="(a, i) in activites"
        :activity-uid="a"
        :key="i"
      />
    </ul>
  </div>
</template>
    
    <script>
    import Activity from './activity.vue'
    export default {
        components: { Activity },
        props : {
            object: {type: Object, required: true}
        },
        data(){
            return {
    
            }
        },
        methods: {
            
        },
        computed: {
            activites(){
                if(this.object !== null && this.object !== undefined) return JSON.parse(this.object.activities)
                return [] 
            }
            
        }
    
    }
    </script>
    
    <style lang="scss" scoped>
    
    </style>